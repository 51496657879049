/** @format */
import { Divider } from "@mui/material";
import { useLocales } from "locales";
import { Dialog } from "ui";
import ContactCard from "../ContactCard";

export default function (props) {
	const { t } = useLocales();

	return (
		<Dialog title={t("contactJesse")} {...props}>
			If you're seeking a dedicated and skilled software engineer, your search ends here. I have a strong
			background in coding and problem-solving, I'm ready to contribute my expertise to your team. Whether no
			matter the software-related project, I'm here to help you achieve your goals. Let's collaborate and
			build something amazing together. Please don't hesitate to reach out to me. I'm excited to discuss how I
			can contribute to your success.
			<Divider sx={{ margin: "20px 0" }} />
			<ContactCard size="small" />
		</Dialog>
	);
}
