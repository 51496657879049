/** @format */
import { Box, Link, styled } from "@mui/material";
import { useLocales } from "locales";
import { useUI } from "ui";

const StyledLink = styled(Link)(({ theme }) => ({
	"&": {
		color: theme.palette.mode == "dark" ? "#FEFEFE" : "#121212",
		margin: "0px 20px",
		textDecoration: "none",
	},
}));

export default function ({ isSmallScreen }) {
	const { t } = useLocales();
	const { dialog } = useUI();

	return (
		<>
			<Box>
				<StyledLink
					href="#"
					onClick={() => dialog.open("what")}
					variant="contained"
					size="small"
					aria-label="dark-mode"
					sx={{ minHeight: "30px", maxWidth: "0px" }}
				>
					{t(isSmallScreen ? "hireShort" : "hireLong")}
				</StyledLink>
			</Box>
		</>
	);
}
