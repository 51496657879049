/** @format */

import { Box, useMediaQuery, useTheme } from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function Skills() {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down("xs"));
	const isSm = useMediaQuery(theme.breakpoints.down("sm"));

	// Determine the number of slides to show
	const slidesToShow = isXs ? 5 : isSm ? 10 : 20;

	return (
		<Box
			sx={{
				width: "100%",
				justifyContent: "center",
				padding: "20px",
				backgroundColor: theme.palette.mode == "light" ? "rgba(12,12,12,0.1)" : "rgba(255,255,255,0.1)",
				borderRadius: "3px",
			}}
		>
			<Slider
				{...{
					dots: false,
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					autoplay: true,
					speed: 3000,
					autoplaySpeed: 0,
					cssEase: "linear",
					arrows: false,
					pauseOnHover: false,
					slidesToShow: slidesToShow,
				}}
			>
				<Box>
					<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/javascript-colored.svg"
							width="36"
							height="36"
							alt="JavaScript"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.php.net/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/php-colored.svg"
							width="36"
							height="36"
							alt="PHP"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.python.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/python-colored.svg"
							width="36"
							height="36"
							alt="Python"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://developer.mozilla.org/en-US/docs/Glossary/HTML5" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/html5-colored.svg"
							width="36"
							height="36"
							alt="HTML5"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://reactjs.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/react-colored.svg"
							width="36"
							height="36"
							alt="React"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://jquery.com/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/jquery-colored.svg"
							width="36"
							height="36"
							alt="JQuery"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.w3.org/TR/CSS/#css" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/css3-colored.svg"
							width="36"
							height="36"
							alt="CSS3"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://sass-lang.com/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/sass-colored.svg"
							width="36"
							height="36"
							alt="Sass"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/bootstrap-colored.svg"
							width="36"
							height="36"
							alt="Bootstrap"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://mui.com/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/materialui-colored.svg"
							width="36"
							height="36"
							alt="Material UI"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://redux.js.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/redux-colored.svg"
							width="36"
							height="36"
							alt="Redux"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://webpack.js.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/webpack-colored.svg"
							width="36"
							height="36"
							alt="Webpack"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://babeljs.io/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/babel-colored.svg"
							width="36"
							height="36"
							alt="Babel"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://vitejs.dev/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/vite-colored.svg"
							width="36"
							height="36"
							alt="Vite"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://nodejs.org/en/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/nodejs-colored.svg"
							width="36"
							height="36"
							alt="NodeJS"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://expressjs.com/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/express-colored.svg"
							width="36"
							height="36"
							alt="Express"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://graphql.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/graphql-colored.svg"
							width="36"
							height="36"
							alt="GraphQL"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.mysql.com/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/mysql-colored.svg"
							width="36"
							height="36"
							alt="MySQL"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.postgresql.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/postgresql-colored.svg"
							width="36"
							height="36"
							alt="PostgreSQL"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://firebase.google.com/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/firebase-colored.svg"
							width="36"
							height="36"
							alt="Firebase"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://flask.palletsprojects.com/en/2.0.x/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/flask-colored.svg"
							width="36"
							height="36"
							alt="Flask"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.digitalocean.com" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/digitalocean-colored.svg"
							width="36"
							height="36"
							alt="Digital Ocean"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.linux.org" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/linux-colored.svg"
							width="36"
							height="36"
							alt="Linux"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://apple.com" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/macos-colored.svg"
							width="36"
							height="36"
							alt="MacOS"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://pytorch.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/pytorch-colored.svg"
							width="36"
							height="36"
							alt="PyTorch"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.raspberrypi.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/raspberrypi-colored.svg"
							width="36"
							height="36"
							alt="Raspberry Pi"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.tensorflow.org/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/tensorflow-colored.svg"
							width="36"
							height="36"
							alt="TensorFlow"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.adobe.com/uk/products/photoshop.html" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/photoshop-colored.svg"
							width="36"
							height="36"
							alt="Photoshop"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.adobe.com/uk/products/illustrator.html" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/illustrator-colored.svg"
							width="36"
							height="36"
							alt="Illustrator"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.adobe.com/uk/products/aftereffects.html" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/aftereffects-colored.svg"
							width="36"
							height="36"
							alt="After Effects"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://www.adobe.com/uk/products/premiere.html" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/premierepro-colored.svg"
							width="36"
							height="36"
							alt="Premiere Pro"
						/>
					</a>
				</Box>
				<Box>
					<a href="https://cloud.google.com/" target="_blank" rel="noreferrer">
						<img
							src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/googlecloud-colored.svg"
							width="36"
							height="36"
							alt="Google Cloud"
						/>
					</a>
				</Box>
			</Slider>
		</Box>
	);
}

export default Skills;
