/** @format */
import { Box } from "@mui/material";
import ReactPlayer from "react-player";

// Load this from the Made Live CDN
const ShortVideo =
	"https://us-central.cdn.made.live/Made_Live_Features_Redux_Compressed_e682787a9b/Made_Live_Features_Redux_Compressed_e682787a9b.mp4";

export default function Video({ playing }) {
	return (
		<Box
			sx={{
				marginTop: "20px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				borderRadius: "5px",
				"& video": {
					borderRadius: "5px",
				},
			}}
		>
			<ReactPlayer
				playing={playing}
				url={ShortVideo}
				controls={true}
				width="80%"
				height="300"
				onEnded={() => setSeeMore(true)}
			/>
		</Box>
	);
}
