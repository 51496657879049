/** @format */

import { faGithub, faLinkedin, faThreads } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardContent, Divider, Grid, Hidden, Link, Typography, styled } from "@mui/material";
import React from "react";
import JesseAvatar from "./assets/images/avatar.jpg";

const StyledGrid = styled(Grid)(() => ({
	"&": {
		padding: "5px 0",
	},
}));

function ContactCard({ size }) {
	// Image size
	const imageSize = size == "large" ? 256 : 128;

	return (
		<>
			<Card sx={{ display: "flex", maxWidth: "100%" }}>
				<Hidden smDown>
					<Avatar
						variant="rounded"
						alt="Jesse Richard"
						src={JesseAvatar}
						sx={{ width: imageSize, height: imageSize, margin: 2 }}
					/>
				</Hidden>
				<CardContent sx={{ flex: "1 0 auto" }}>
					<Typography variant="h5" component="div">
						Jesse Richard
					</Typography>
					<Divider sx={{ marginBottom: "5px" }} />
					<StyledGrid container spacing={2} alignItems="center">
						<Grid item>
							<FontAwesomeIcon icon={faPhone} />
						</Grid>
						<Grid item>
							<Typography variant="body1">650-229-2993</Typography>
						</Grid>
					</StyledGrid>
					<StyledGrid container spacing={2} alignItems="center">
						<Grid item>
							<FontAwesomeIcon icon={faEnvelope} />
						</Grid>
						<Grid item>
							<Typography variant="body1">
								<Link href="mailto:no-relay@jessejamesrichard.com">no-relay@jessejamesrichard.com</Link>
							</Typography>
						</Grid>
					</StyledGrid>
					<StyledGrid container spacing={2} alignItems="center">
						<Grid item>
							<FontAwesomeIcon icon={faLinkedin} />
						</Grid>
						<Grid item>
							<Link href="https://linkedin.com/in/jesse-james-richard" target="_blank">
								<Typography variant="body1">linkedin.com/in/jesse-james-richard</Typography>
							</Link>
						</Grid>
					</StyledGrid>
					<StyledGrid container spacing={2} alignItems="center">
						<Grid item>
							<FontAwesomeIcon icon={faThreads} />
						</Grid>
						<Grid item>
							<Link href="https://threads.net/@jessejamesrich" target="_blank">
								<Typography variant="body1">threads.net/@jessejamesrich</Typography>
							</Link>
						</Grid>
					</StyledGrid>
					<StyledGrid container spacing={2} alignItems="center">
						<Grid item>
							<FontAwesomeIcon icon={faGithub} />
						</Grid>
						<Grid item>
							<Link href="https://github.com/jessejamesrich" target="_blank">
								<Typography variant="body1">github.com/jessejamesrich</Typography>
							</Link>
						</Grid>
					</StyledGrid>
					<Divider />
				</CardContent>
			</Card>
		</>
	);
}

export default ContactCard;
